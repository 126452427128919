<template>
  <div class="page">
    <el-main>
      <el-container>
        <el-row>
          <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form :inline="true">
              <el-form-item>
                <el-select
                  v-model="personType"
                  placeholder="请选择人员类型"
                  style="width: 100px"
                  @change="personChange"
                  size="medium"
                >
                  <el-option
                    v-for="(item, i) in personTypeData"
                    :key="i"
                    :label="item.title"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-show="personType == 0">
                <el-select
                  v-model="checkClass"
                  multiple
                  filterable
                  collapse-tags
                  placeholder="班级"
                  style="width: 220px"
                  @change="SearchPage()"
                  size="medium"
                >
                  <el-option
                    v-for="(item, i) in checkClassData"
                    :key="i"
                    :label="item.ClassName"
                    :value="item.Id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item required>
                <el-select
                  v-model="cardOperationType"
                  multiple
                  filterable
                  collapse-tags
                  placeholder="类型"
                  style="width: 160px"
                  @change="SearchPage()"
                  size="medium"
                >
                  <el-option
                    v-for="(item, i) in cardOperationTypeData"
                    :key="i"
                    :label="item.title"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-input
                v-model="keyword"
                placeholder="请输入关键字查询"
                class="input-with-select"
                style="width: 300px; margin-right: 10px"
                size="medium"
              >
                <template #prepend>
                  <el-select
                    v-model="checkInput"
                    multiple
                    collapse-tags
                    placeholder="请选择"
                    style="width: 120px"
                  >
                    <el-option
                      v-for="(item, i) in dataTableSearch"
                      :key="i"
                      :label="item.label"
                      :value="item.attr"
                    ></el-option>
                  </el-select>
                </template>
              </el-input>
              <el-form-item label="">
                <el-date-picker
                  v-model="selectDate"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  size="medium"
                >
                </el-date-picker>
              </el-form-item>
              <slot name="searchSlot"></slot>
              <el-form-item>
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  size="medium"
                  @click="SearchPage()"
                  >查询</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button
                  icon="el-icon-postcard"
                  type="primary"
                  size="medium"
                  @click="personHandCard()"
                  >管理</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-popover placement="bottom" :width="400" trigger="click">
                  <template #reference>
                    <el-button size="medium"
                      ><i class="el-icon-arrow-down el-icon-menu" /> 列筛选</el-button
                    >
                  </template>
                  <el-checkbox-group v-model="dataTableSearchList">
                    <div v-for="(item, i) in dataTableList" :key="i">
                      <el-checkbox
                        v-if="item.visible != false"
                        :label="item"
                        checked
                        style="display: block; margin: 10px"
                        @change="filterFunHandle('filter', item.attr, i)"
                      >
                        {{ item.label }}</el-checkbox
                      >
                      <el-checkbox
                        v-else
                        :label="item"
                        style="display: block; margin: 10px"
                        @change="filterFunHandle('filter', item.attr, i)"
                      >
                        {{ item.label }}</el-checkbox
                      >
                    </div>
                  </el-checkbox-group>
                  <el-button
                    size="small"
                    type="text"
                    @click="filterFunHandle('allchecked')"
                    >全选</el-button
                  >
                  <el-button size="small" type="text" @click="filterFunHandle('cancel')"
                    >取消全选</el-button
                  >
                </el-popover>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-table
              size="small"
              border
              ref="multipleTable"
              :data="dataTable"
              style="width: 99%"
              max-height="700"
              :height="tableHeight"
              :fit="true"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                fixed="left"
                header-align="center"
                align="center"
              ></el-table-column>
              <!-- <el-table-column
                label="操作"
                fixed="right"
                header-align="center"
                align="center"
                width="100"
              >
                <template #default="scope">
                  <el-popconfirm
                    confirmButtonText="确定"
                    cancelButtonText="取消"
                    icon="el-icon-info"
                    iconColor="red"
                    title="确定删除吗？"
                    @confirm="personDelete(scope.row.Id)"
                  >
                    <template #reference>
                      <el-button type="danger" v-has="'FakeDelete'" size="mini" plain
                        >删除</el-button
                      >
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column> -->
              <el-table-column
                v-for="(col, index) in dataTableSearchList"
                :key="index"
                sortable
                header-align="center"
                align="center"
                :prop="col.attr"
                :label="col.label"
              >
                <template #default="scope">
                  <el-image
                    v-if="col.type == 'img'"
                    :src="scope.row[col.attr]"
                    :preview-src-list="[scope.row[col.attr]]"
                    fit="fill"
                    style="width: 40px; height: 40px"
                  ></el-image>
                  <el-tag size="medium" v-else-if="col.type == 'enum'">
                    {{ col.formatters(scope.row[col.attr]) }}
                  </el-tag>

                  <el-image
                    v-else-if="col.type == 'custom'"
                    style="width: 50px; height: 50px; margin-left: 5px"
                    v-for="ite in scope.row[col.attr]"
                    :key="ite"
                    :src="ite.FaceUrl"
                    :preview-src-list="[ite.FaceUrl]"
                    lazy
                  ></el-image>

                  <label v-else>{{ scope.row[col.attr] }}</label>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <paging
              :currentPage="currentPage"
              :pagesize="pageSize"
              :total="total"
              @sizeChange="handleSizeChange"
              @currentChange="handleCurrentChange"
              :pagesizes="pageSizes"
            />
          </el-col>
        </el-row>
      </el-container>
    </el-main>
    <el-dialog title="账户(卡)管理" v-model="dialogVisible" width="30%" center>
      <el-form
        label-position="left"
        label-width="80px"
        :model="formData"
        :rules="formDataRules"
        ref="formData"
      >
        <el-form-item label="人员类型" prop="PersonType">
          <el-select
            v-model="formData.PersonType"
            placeholder="请选择人员类型"
            @change="selectPersonChange"
            size="medium"
          >
            <el-option
              v-for="(item, i) in personTypeData"
              :key="i"
              :label="item.title"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择人员" prop="PersonId">
          <el-select
            v-model="formData.PersonId"
            placeholder="请选择人员"
            size="medium"
            filterable
            @change="checkhandPersonCard()"
          >
            <el-option
              v-for="(item, i) in personData"
              :key="i"
              :label="item.title"
              :value="item.value"
            >
              <span style="float: left">{{ item.title }}</span>
              <span
                style="
                  float: right;
                  color: var(--el-text-color-secondary);
                  font-size: 13px;
                "
                >{{ item.tag }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡类型" prop="CardType">
          <el-select
            v-model="formData.CardType"
            placeholder="请选择卡类型"
            size="medium"
            @change="checkhandPersonCard()"
          >
            <el-option
              v-for="(item, i) in cardTypeData"
              :key="i"
              :label="item.title"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作类型" prop="CardOperationType">
          <el-select
            v-model="formData.CardOperationType"
            placeholder="请选择卡操作类型"
            size="medium"
          >
            <el-option
              v-for="(item, i) in formCardOperationTypeData"
              :key="i"
              :label="item.title"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡号" prop="CardNo">
          <el-input v-model="formData.CardNo"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="RemarkInfo">
          <el-input
            type="textarea"
            v-model="formData.RemarkInfo"
            placeholder="请输入备注（长度不大于100个字符）"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="(dialogVisible = false), resetForm('formData')"
            >取 消</el-button
          >
          <el-button type="primary" @click="submitForm('formData')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
let _this;
export default {
  name: "cardInfo",
  components: {},
  data() {
    return {
      dialogVisible: false,
      personType: 0,
      personTypeData: [
        { title: "学生", value: 0 },
        { title: "教职工", value: 1 },
      ],
      cardOperationType: [],
      cardOperationTypeData: [
        { title: "开卡", value: 0 },
        { title: "换卡", value: 1 },
        { title: "补卡", value: 2 },
      ],
      checkClass: [],
      checkClassData: [],
      personData: [],
      cardTypeData: [
        { title: "智慧校园卡", value: 0 },
        { title: "门禁卡", value: 1 },
        { title: "水卡", value: 2 },
        { title: "消费(饭)卡", value: 3 },
        { title: "亲情话机卡", value: 4 },
      ],
      formCardOperationTypeData: [],
      formData: {
        PersonType: 0,
        PersonId: "",
        CardNo: "",
        CardType: 0,
        CardOperationType: "",
        RemarkInfo: "",
      },
      formDataRules: {
        PersonType: [
          {
            required: true,
            trigger: "blur",
            message: "请选择人员类型",
          },
        ],
        PersonId: [
          {
            required: true,
            trigger: "blur",
            message: "请选择人员",
          },
        ],
        CardType: [
          {
            required: true,
            trigger: "blur",
            message: "请选择卡类型",
          },
        ],
        CardOperationType: [
          {
            required: true,
            trigger: "blur",
            message: "请选择卡操作类型",
          },
        ],
        CardNo: [
          {
            required: true,
            trigger: "blur",
            message: "请输入卡号",
          },
          {
            min: 0,
            max: 32,
            message: "长度不大于32个字符",
            trigger: "blur",
          },
        ],
        RemarkInfo: [
          {
            min: 0,
            max: 100,
            message: "长度不大于100个字符",
            trigger: "blur",
          },
        ],
      },
      selectDate: "",
      total: 0, //数据总条数
      pageSize: 10, //当前显示数据数量
      pageSizes: [10, 20, 50, 100, 200, 500, 1000, 2000],
      currentPage: 1, //分页当前页
      keyword: "", //搜索输入关键字
      checkInput: "", //选择所需要查询的内容
      dataConfig: [
        {
          label: "Id",
          attr: "Id",
          isInput: false,
          isDisplay: false,
        },
        {
          label: "部门名称",
          attr: "PersonDeptName",
          isSearch: true,
        },
        {
          label: "人员姓名",
          attr: "PersonName",
          isSearch: true,
        },
        {
          label: "人员类型",
          attr: "PersonType",
          type: "enum",
          formatters: function (val) {
            var result;
            switch (val) {
              case 0:
                result = "学生";
                break;
              case 1:
                result = "教职工";
                break;
              default:
                result = "未知";
                break;
            }
            return result;
          },
        },
        {
          label: "卡号",
          attr: "CardNo",
          isSearch: true,
        },
        {
          label: "卡类型",
          attr: "CardType",
          type: "enum",
          formatters: function (val) {
            var result;
            switch (val) {
              case 0:
                result = "智慧校园卡";
                break;
              case 1:
                result = "门禁卡";
                break;
              case 2:
                result = "水卡";
                break;
              case 3:
                result = "消费(饭)卡";
                break;
              case 4:
                result = "亲情话机卡";
                break;
              default:
                result = "未知";
                break;
            }
            return result;
          },
        },
        {
          label: "卡状态",
          attr: "CardStatus",
          type: "enum",
          formatters: function (val) {
            var result;
            switch (val) {
              case 0:
                result = "正常";
                break;
              case 1:
                result = "丢失";
                break;
              case 2:
                result = "销户";
                break;
              default:
                result = "未知";
                break;
            }
            return result;
          },
        },
        {
          label: "卡操作类型",
          attr: "CardOperationType",
          type: "enum",
          formatters: function (val) {
            var result;
            switch (val) {
              case 0:
                result = "开卡";
                break;
              case 1:
                result = "换卡";
                break;
              case 2:
                result = "补卡";
                break;
              default:
                result = "未知";
                break;
            }
            return result;
          },
        },
        {
          label: "备注",
          attr: "RemarkInfo",
          isSearch: true,
          visible: false,
        },
        {
          label: "更新时间",
          attr: "UpdatedTime",
        },
        {
          label: "创建时间",
          attr: "CreatedTime",
          visible: false,
        },
      ],
      dataTableSearch: [], //搜索信息
      dataTableList: [], //显示列信息
      dataTableSearchList: [], //列筛选信息
      dataTable: [], //显示数据
      multipleSelection: [], //选中数据
      tableHeight: 200, //table高度
    };
  },
  created() {
    _this = this;
  },
  mounted() {
    _this.$nextTick(function () {
      // 仅在整个视图都被渲染之后才会运行的代码
      //设置table位置
      let heightStyle = window.innerHeight - 230;
      _this.tableHeight = heightStyle;

      //窗口大小改变时，table 高度设置
      window.onresize = () => {
        return (() => {
          let heightStyle = window.innerHeight - 230;
          _this.tableHeight = heightStyle;
        })();
      };
    });
    _this.initCheckClassList();
    _this.SearchPage();
    _this.dataTableSearch = _this.dataConfig.filter((it) => it.isSearch == true); //可搜索字段
    _this.dataTableList = _this.dataConfig.filter((it) => it.isDisplay != false); //可显示列
  },
  methods: {
    //初始化班级选择数据
    initCheckClassList() {
      _this.$API.Classes.GetClassesDataList.get().then((res) => {
        _this.checkClassData = res;
      });
    },
    personChange() {
      if (_this.personType == 1) {
        _this.checkClass = [];
      }
      _this.SearchPage();
    },
    selectPersonChange() {
      var data = [];
      _this.formData.PersonId = "";
      if (_this.formData.PersonType == 1) {
        _this.$API.ClassTeacherSubject.GetWorkerList.get().then((res) => {
          if (res) {
            res.forEach((it) => {
              data.push({
                value: it.WorkerId,
                title: `${it.WorkerName}`,
                tag: it.WorkerPhone,
              });
            });
          }
        });
      } else {
        var json = {
          StudentIds: [],
          ClassIds: [],
          Grades: [],
        };
        _this.$API.Student.GetStudentList.post(json).then((res) => {
          if (res) {
            res.forEach((it) => {
              data.push({
                value: it.Id,
                title: `${it.StudentName}(${it.ClassName})`,
                tag: it.StudentPhone,
              });
            });
          }
        });
      }
      _this.personData = data;
    },
    personHandCard() {
      _this.formData.PersonType=_this.personType;
      _this.selectPersonChange();
      _this.formCardOperationTypeData = _this.cardOperationTypeData;
      _this.dialogVisible = true;
    },
    checkhandPersonCard() {
      _this.formData.CardOperationType = "";
      _this.$API.CardInfo.GetPersonHandCardInfo.get(
        _this.formData.PersonType,
        _this.formData.PersonId,
        _this.formData.CardType
      ).then((res) => {
        if (res == null || res == undefined) {
          _this.formCardOperationTypeData = _this.cardOperationTypeData;
        } else {
          _this.formCardOperationTypeData = _this.cardOperationTypeData.filter(
            (it) => it.value != 0
          );
        }
      });
    },
    //页面搜索查询
    SearchPage() {
      let json = {
        searchValue: "",
        pageNo: _this.currentPage,
        pageSize: _this.pageSize,
        sortField: "",
        sortOrder: "",
        descStr: "",
        PersonType:_this.personType,
        ClassIds: _this.checkClass,
        CardOperationTypes:_this.cardOperationType
      };
      if (_this.checkInput.length > 0 && _this.keyword != "") {
        var searchParameters = [],
          input = _this.keyword;
        for (let index = 0; index < _this.checkInput.length; index++) {
          const element = _this.checkInput[index];
          var searchParametersItem = {
            field: element,
            op: 8,
            value: input,
            orGroup: "",
          };
          searchParameters.push(searchParametersItem);
        }
        json.searchParameters = searchParameters;
      }
      if (_this.selectDate && _this.selectDate.length == 2) {
        json.searchBeginTime = _this.selectDate[0];
        json.searchEndTime = _this.selectDate[1];
      }
      _this.$API.CardInfo.QueryByPage.post(json).then((res) => {
        if (res) {
          _this.total = res.TotalRows;
          _this.pagesize = res.PageSize;
          _this.currentPage = res.PageNo;
          _this.dataTable = res.Rows;
        }
      });
    },
    handleSizeChange(val) {
      _this.pageSize = val;
      _this.SearchPage();
    },
    handleCurrentChange(val) {
      _this.currentPage = val;
      _this.SearchPage();
    },
    handleSelectionChange(val) {
      _this.multipleSelection = val;
    },
    // 列筛选
    filterFunHandle(type, currentItem, index) {
      if (type === "allchecked") {
        // 全选
        _this.dataTableSearchList = _this.dataConfig.filter(
          (it) => it.isDisplay != false
        );
      } else if (type === "cancel") {
        // 取消全选
        _this.dataTableSearchList = []; // 复选框置为空，全部不选择
      }
    },
    operationSuccess() {
      //操作成功后，关闭弹出框，刷新数据显示
      _this.dialogVisible = false;
      _this.SearchPage();
    },
    handleClose(done) {
      _this
        .$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    submitForm(formName) {
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.$API.CardInfo.AddOrUpdate.post(_this.formData).then((res) => {
             _this.dialogVisible = false;
            if (res.Success) {
              _this.$message.success(res.Message);
            }else{
              _this.$message.error(res.Message);
            }
             _this.operationSuccess();
             _this.resetForm('formData');
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      _this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped>
.page {
  margin: 5px;
  padding: 20px;
  min-height: 840px;
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  width: auto;
}
.drawerTitle {
  color: #409eff;
}
.stime {
  width: 120px;
}
</style>
